export default {
  'addressAdministrativeMunicipality.name': 'Name',
  company_id: 'Firma Stammdaten',
  'addressAdministrativeMunicipality.street': 'Straße',
  'addressAdministrativeMunicipality.zip': 'PLZ',
  'addressAdministrativeMunicipality.city': 'Ort',
  'contact.contactName': 'Ansprechpartner',
  'contact.phone': 'Telefon',
  'contact.email': 'Email',
  'contact.fax': 'Fax',
  referenceNumber: 'Kassenzeichen',
  municipality: 'Gemeinde',
  creditor: 'Kreditor',
  creditorOld: 'Kreditor Alt',
  'bank.iban': 'IBAN',
  'bank.bic': 'BIC',
  'bank.bType': 'BTyp',
  belongedToGrid: 'Netzzugehörigkeit',
  'belongedToGrid.from': 'Netzzugehörigkeit von',
  'belongedToGrid.to': 'Netzzugehörigkeit bis',
  smallCrossBorderTraffic: 'Kleiner Grenzverkehr',
  parentMunicipalityAgs: 'AGS aufn. Kommune',
  ags: 'AGS',
  zip: 'PLZ Gemeinde',
  isAgsOld: 'AGS Alt',
  suburbanisedAt: 'Eingemeindet seit',
  uniformMunicipalRateSince: 'Einheitl. Hebesatz seit',
  'ihkName.value': 'IHK {year}',
  'municipalRate.value': 'Hebesatz {year}',
}
