import BaseApi from './BaseApi'
import { mimes } from '@/api/mimes'

class TradeTaxApi extends BaseApi {
  getAll(page, archived = false) {
    return this.get(
      `/trade_tax_clearings?page=${page}&archived=${Number(archived)}`
    )
  }
  getTax(id) {
    return this.get(`/trade_tax_clearings/${id}`)
  }
  upload(taxData) {
    return this.post(`/trade_tax_clearings/upload`, taxData)
  }
  update(id, taxData) {
    return this.put(`/trade_tax_clearings/${id}`, taxData)
  }
  exportTax(id, fileName, mime = mimes.zip) {
    const url = `/trade_tax_clearing/export/${id}`
    const method = 'get'
    return this.downloadAsFile({ url, method }, mime, fileName)
  }
  archiveTax(id, archived) {
    return this.post(`/trade_tax_clearings/${id}/archive`, { archived })
  }
}

export default new TradeTaxApi()
