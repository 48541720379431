export class TradeTax {
  constructor() {
    this.company_id = null
    this.comment = ''
    this.calculation_type = null
    this.profit = 0
    this.year = null
    this.trade_tax_clearing_data = []
    this.wage_weight = 0
    this.kwh_weight = 0
    this.gas_meters_weight = 0
    this.lower_wage_sum_weight = 0
  }
}
