<template>
  <v-card>
    <table class="v-datatable v-table theme--light">
      <tbody>
        <tr>
          <td><strong>Spaltenkopf</strong></td>
          <td
            :key="item.expected + index"
            class="text-xs-center"
            v-for="(item, index) in structure"
          >
            <strong>{{ item.expected }}</strong>
          </td>
        </tr>
        <tr>
          <td><strong>Upload Datei Kopf</strong></td>
          <td
            :key="index + item.expected"
            class="text-xs-center"
            v-for="(item, index) in structure"
          >
            <v-icon
              class="success--text"
              v-if="compareHeaders(item.expected, item.header)"
              >check</v-icon
            >
            <span class="error--text" v-else>{{ item.header }}</span>
          </td>
        </tr>
        <tr>
          <td><strong>Beispielwert</strong></td>
          <td
            :key="item.expected + index"
            class="text-xs-center"
            v-for="(item, index) in dataStructure"
          >
            <strong>{{ item.expected }}</strong>
          </td>
        </tr>
        <tr>
          <td><strong>Upload Datei Wert</strong></td>
          <td
            :key="index + item.expected"
            class="text-xs-center"
            v-for="(item, index) in dataStructure"
          >
            <v-icon class="success--text" v-if="item.matches === true"
              >check</v-icon
            >
            <span class="error--text" v-else-if="item.matches === false">{{
              item.entry
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <v-card-actions v-if="isValid">
      <v-spacer></v-spacer>
      <strong class="mx-3">
        <v-icon class="success--text">check</v-icon>
        Datei korrekt
      </strong>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'TradeTaxCSVStructureComparator',
  props: {
    structure: {
      type: Array,
      required: true,
    },
    dataStructure: {
      type: Array,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
    compareHeaders: {
      type: Function,
      required: true,
    },
  },
}
</script>
