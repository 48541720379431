<template>
  <v-card>
    <v-card-title>
      <v-layout>
        <v-spacer />
        <v-flex mx4 xs6>
          <v-text-field
            clearable
            label="Suche"
            placeholder="AGS, Ort, Betrag, ..."
            v-model="search"
          />
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-data-table
      :rows-per-page-items="[50, 100]"
      v-bind="{ headers, items, search }"
    >
      <template v-slot:items="props">
        <td>{{ props.item.ags }}</td>
        <template v-if="props.item.master_data">
          <td>{{ props.item.master_data.municipality }}</td>
          <td>{{ props.item.master_data.parentMunicipality }}</td>
        </template>
        <template v-else>
          <td />
          <td />
        </template>

        <td class="text-xs-right" v-if="baseType === 'wage_sum_type'">
          {{ props.item.wage_sum | formatAmountToCurrency }}
        </td>

        <template v-if="baseType === 'consumption_type'">
          <td class="text-xs-right" v-if="type === 'rlm' || type === 'slp'">
            {{ props.item.gas_meters }}
          </td>
          <td class="text-xs-right">
            {{ props.item.kwh | formatKwhUsage }}
          </td>
        </template>
        <td class="text-xs-right">
          <v-btn icon @click="selectRow(props.item.id)">
            <v-icon>edit</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
    <ModalTradeTaxEdit
      @editRow="
        (id, row) => {
          $emit('editRow', id, row)
        }
      "
      v-model="dialog"
      :editable-row="editableRow"
    />
  </v-card>
</template>

<script>
import ModalTradeTaxEdit from '@/pages/zerlegungView/ModalTradeTaxEdit'
import { formatAmountToCurrency } from '@/utils/paymentUtils'
import { formatKwhUsage } from '@/utils/filters'

export default {
  name: 'TaxTable',
  components: { ModalTradeTaxEdit },
  props: {
    tradeTaxData: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    baseType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      editableRow: null,
      search: '',
    }
  },
  computed: {
    headers() {
      const headers = [
        { text: 'AGS', value: 'ags', sortable: true },
        {
          text: 'Ort/Kommune',
          value: 'master_data.municipality',
          sortable: true,
        },
        {
          text: 'Ortst. von',
          value: 'master_data.parentMunicipality',
          sortable: true,
        },
      ]
      if (this.baseType === 'wage_sum_type') {
        headers.push({
          text: 'Lohnsumme',
          value: 'wage_sum',
          align: 'right',
          sortable: true,
        })
      }
      if (this.baseType === 'consumption_type') {
        if (['rlm', 'slp'].includes(this.type)) {
          headers.push({
            text: 'Anzahl Zähler',
            value: 'gas_meters',
            align: 'right',
            sortable: true,
          })
        }
        headers.push({
          text: 'Verbrauch',
          value: 'kwh',
          align: 'right',
          sortable: true,
        })
      }
      headers.push({ text: '', value: '', sortable: false })
      return headers
    },
    items() {
      return this.tradeTaxData
    },
  },
  methods: {
    selectRow(id) {
      const row = this.items.find((elem) => elem.id === id)
      this.editableRow = { ...row }
      this.dialog = true
    },
  },
  filters: {
    formatAmountToCurrency,
    formatKwhUsage,
  },
}
</script>

<style scoped></style>
