<template>
  <v-dialog max-width="500" persistent v-model="dialog">
    <v-card v-if="editableRow">
      <v-card-title class="headline">
        Daten {{ editableRow.ags }}
      </v-card-title>
      <v-card-text>
        <v-text-field :value="rowObject.ags" box disabled label="AGS" />
        <template v-if="rowObject.wage_sum_type">
          <SimpleMoneyInput
            :label="getLabel('wage_sum_type')"
            v-model="rowObject.wage_sum"
          />
        </template>

        <template v-if="rowObject.consumption_type">
          <v-text-field
            box
            label="Anzahl Gaszähler"
            type="number"
            v-if="rowObject.consumption_type !== 'normal'"
            v-model.number="rowObject.gas_meters"
          />
          <v-text-field
            box
            label="Kilowattstunden"
            type="number"
            v-model.number="rowObject.kwh"
          />
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer />

        <v-btn @click="close">Abbrechen</v-btn>
        <v-btn @click="editRow" color="success">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { translateTradeTaxCalcType } from '@/utils/filters'
import SimpleMoneyInput from '@/pages/zerlegungView/SimpleMoneyInput'

export default {
  name: 'ModalTradeTaxEdit',
  components: { SimpleMoneyInput },
  props: {
    value: Boolean,
    editableRow: Object,
  },
  data() {
    return {
      selected: [],
      rowObject: {},
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        return this.$emit('input', value)
      },
    },
  },

  methods: {
    close() {
      this.dialog = false
    },
    getLabel(str) {
      return translateTradeTaxCalcType(`${str}-${this.rowObject[str]}`)
    },
    editRow() {
      this.$emit('editRow', this.editableRow.id, this.rowObject)
      this.close()
    },
  },
  watch: {
    editableRow(value) {
      this.rowObject = { ...value }
    },
  },
}
</script>
