import { render, staticRenderFns } from "./Yearpicker.vue?vue&type=template&id=152c811c&scoped=true&"
import script from "./Yearpicker.vue?vue&type=script&lang=js&"
export * from "./Yearpicker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152c811c",
  null
  
)

export default component.exports