<template>
  <v-layout class="mb-4" wrap>
    <v-flex xs12>
      <h2 class="title">{{ file.text }}</h2>
    </v-flex>
    <v-flex xs4>
      <input
        :key="`input-${inputKey}`"
        :id="id"
        @change="handleFileSelect"
        ref="input"
        accept=".csv"
        name="file"
        type="file"
        v-show="false"
      />
      <label :for="id" class="v-btn primary" style="cursor: pointer"
        >Upload
      </label>
      <template v-if="fileObject">
        <p class="ml-3">{{ fileObject.name }}</p>
        <p class="ml-3">
          <strong>Dateigröße:</strong>
          {{ fileObject.size | niceFileSize }}
        </p>
        <p class="ml-3"><strong>Einträge:</strong> {{ parsedItems }}</p>
        <v-btn @click="removeSelection" small>Entfernen</v-btn>
      </template>
    </v-flex>
    <v-flex v-if="fileStructureComparator.length" xs8>
      <TradeTaxCSVStructureComparator
        :compare-headers="compareHeaders"
        :is-valid="isFileValid"
        :structure="fileStructureComparator"
        :data-structure="dataStructureComparator"
      />
    </v-flex>
    <v-flex xs12>
      <v-divider />
    </v-flex>
  </v-layout>
</template>

<script>
import Papa from 'papaparse'
import { niceFileSize } from '@/utils/niceSize'
import TradeTaxCSVStructureComparator from '@/pages/zerlegungView/addTaxFile/TradeTaxCSVStructureComparator'

let inputCounter = 0

function defaultData() {
  return {
    inputKey: inputCounter++,
    fileObject: null,
    parsedHeaders: null,
    parsedItems: 0,
    firstDataRow: null,
    errors: [],
  }
}

export default {
  name: 'AddTaxFile',
  components: { TradeTaxCSVStructureComparator },
  props: {
    file: Object,
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ...defaultData(),
    }
  },
  computed: {
    fileStructureComparator() {
      if (!this.parsedHeaders) {
        return this.file.csvStructure.headers.map((expected) => {
          return { header: '', expected }
        })
      }
      return this.parsedHeaders.map((header, index) => {
        const expected = this.file.csvStructure.headers[index] || ''
        return {
          header: header.trim(),
          expected: expected.trim(),
        }
      })
    },
    dataStructureComparator() {
      if (!this.firstDataRow) {
        return this.file.csvStructure.exampleValues.map((expected) => {
          return { entry: '', expected, matches: null }
        })
      }
      return this.firstDataRow.map((entry, index) => {
        const expected = this.file.csvStructure.exampleValues[index] || ''
        const pattern = this.file.csvStructure.patterns[index]
        const matches = pattern ? pattern.test(entry) : false
        return {
          entry,
          expected,
          matches,
        }
      })
    },
    dataComparator() {
      if (this.firstDataRow) {
        const patterns = this.file.csvStructure.patterns
        if (this.firstDataRow.length === patterns.length) {
          return this.firstDataRow
            .map((el, idx) => {
              return patterns[idx].test(el)
            })
            .every(Boolean)
        }
      }
      return false
    },
    isFileValid() {
      const allHeadersMatch = this.fileStructureComparator
        .map((el) => this.compareHeaders(el.header, el.expected))
        .every(Boolean)

      return (
        this.parsedItems > 0 &&
        this.errors.length === 0 &&
        allHeadersMatch &&
        this.dataComparator
      )
    },
  },
  methods: {
    compareHeaders(a = '', b = '') {
      return a.toLowerCase().trim() === b.toLowerCase().trim()
    },

    removeSelection() {
      Object.assign(this.$data, defaultData())
      this.$emit('addFile', this.file.name, null)
    },
    handleFileSelect(evt) {
      if (evt.target.files.length) {
        this.fileObject = evt.target.files[0]
        const reader = new FileReader()
        reader.onload = (file) => {
          const fileContent = file.target.result
          const { data, errors } = Papa.parse(fileContent, {
            header: false,
            skipEmptyLines: true,
          })
          this.parsedHeaders = data[0]
          this.parsedItems = data.length - 1
          if (this.parsedItems > 0) {
            this.firstDataRow = data[1]
          }
          this.errors = errors
          if (this.isFileValid) {
            this.$emit('addFile', this.file.name, this.fileObject)
          } else {
            this.$emit('addFile', this.file.name, null)
          }
        }
        reader.readAsText(this.fileObject)
      } else {
        this.removeSelection()
      }
    },
  },
  filters: {
    niceFileSize,
  },
  watch: {
    fileObject() {},
  },
}
</script>
