export function filterCreditEntriesWithDocumentId(paymentLines, document_id) {
  return paymentLines.filter((line) => {
    const hasDocId = Boolean(
      line.documentDates?.some((doc) => doc.document_id === document_id)
    )
    const hasTaxCreditEntry = line.tax.value < 0
    const hasInterestAdditionalCreditEntry = line.interest_additional.value < 0
    const hasInterestRefundCreditEntry = line.interest_refund.value < 0
    return (
      hasDocId &&
      (hasTaxCreditEntry ||
        hasInterestAdditionalCreditEntry ||
        hasInterestRefundCreditEntry)
    )
  })
}

export function translateTradeTaxCalcType(type) {
  return {
    general: 'Allgemeine Methode',
    enviam: 'enviaM Methode',
    mitgas: 'MITGAS Methode',
    'wage_sum_type-normal': 'Lohndaten',
    'wage_sum_type-lower': 'Unterzerlegung Lohn',
    'wage_sum_type-upper': 'Oberzerlegung Lohn',
    'consumption_type-normal': 'Verbrauchsdaten',
    'consumption_type-rlm': 'Verbrauchsdaten RLM',
    'consumption_type-slp': 'Verbrauchsdaten SLP',
  }[type]
}

export function formatKwhUsage(value) {
  const formatter = new Intl.NumberFormat('de-DE', { style: 'decimal' })
  return `${formatter.format(value)} kWh`
}
