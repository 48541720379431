<template>
  <v-text-field
    :value="value"
    ref="inputElement"
    @change="setDate"
    :rules="[validate]"
    :validate-on-blur="true"
    :hide-details="hideDetails"
    :disabled="disabled"
    :label="label"
    box
    mask="####"
  />
</template>

<script>
export default {
  name: 'Yearpicker',
  props: ['value', 'label', 'hideDetails', 'disabled'],
  methods: {
    validate(value) {
      if (value) {
        value = `${value}`
        const isValid = value.length === 0 || value.length === 4
        return isValid || 'Bitte das Jahr vierstellig eingeben'
      }
      return true
    },
    setDate(newDate) {
      this.$emit('input', newDate)
    },
  },
}
</script>

<style scoped></style>
