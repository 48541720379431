<template>
  <v-alert :value="true">
    <h3 class="subtitle mb-3">Fehler beim beim Speichern</h3>
    <ul :key="key" v-for="(err, key) in errors">
      <li :key="msg" class="pl-3" v-for="msg in err">{{ msg }}</li>
    </ul>
  </v-alert>
</template>

<script>
import fieldnames from '@/pages/masterDataView/fieldnames'
export default {
  name: 'ValidationErrors',
  props: {
    errors: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fields() {
      return Object.keys(this.errors).map((el) => el.split('.'))
    },
    translatedFieldNames() {
      return fieldnames
    },
  },
  methods: {
    normalizeFieldName(field) {
      const [, ...rest] = field.split('.')
      return rest.join('.')
    },
  },
}
</script>

<style scoped></style>
