<template>
  <app-layout>
    <v-layout>
      <v-flex xs12 md12 lg12>
        <v-container grid-list-md>
          <v-layout>
            <v-flex>
              <h2>Gewerbesteuer Zerlegung</h2>
            </v-flex>
            <v-spacer />
            <template v-if="tradeTax">
              <v-btn
                :disabled="loading"
                @click="exportTax"
                color="primary"
                :loading="loadingZip"
                v-if="!create"
                >Zerlegungsberechnung
              </v-btn>
              <v-btn
                :disabled="!isValid"
                :loading="loading"
                @click="save"
                color="primary"
                v-if="tradeTax"
                >Speichern
              </v-btn>

              <v-btn
                :disabled="!isValid"
                :loading="loading"
                @click="archivedChange"
                color="primary"
                v-if="tradeTax && !create"
                >{{ tradeTax.archived ? 'Aktivieren' : 'Archivieren' }}
              </v-btn>
            </template>
          </v-layout>
        </v-container>

        <v-container v-if="validationErrors">
          <ValidationErrors :errors="validationErrors" />
        </v-container>

        <v-container grid-list-md v-if="tradeTax">
          <v-layout wrap>
            <v-flex lg3 sm6 xs12>
              <yearpicker
                :disabled="!create"
                box
                label="Jahr"
                v-model="tradeTax.year"
              />
            </v-flex>

            <v-flex lg3 sm6 xs12>
              <v-select
                :disabled="!create"
                :items="companies"
                box
                item-text="name"
                item-value="id"
                label="Firma"
                v-model="tradeTax.company_id"
              ></v-select>
            </v-flex>

            <v-flex lg3 sm6 xs12>
              <v-text-field
                :disabled="!create"
                box
                label="Gewerbeertrag"
                v-model="tradeTax.profit"
              />
            </v-flex>
            <v-flex lg3 sm6 xs12>
              <v-select
                :items="types"
                :disabled="!create"
                box
                item-text="name"
                item-value="value"
                label="Berechungsart"
                v-model="tradeTax.calculation_type"
              />
            </v-flex>
          </v-layout>
          <v-layout v-if="weightingFields[tradeTax.calculation_type]">
            <v-flex
              :key="key"
              md3
              v-for="(label, key) in weightingFields[tradeTax.calculation_type]"
            >
              <v-text-field
                :label="label"
                :disabled="!create"
                box
                :rules="[
                  weightCorrect || 'Summe aller Gewichtungen muss 100 sein. ',
                ]"
                v-model.number="tradeTax[key]"
              />
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex xs12>
              <v-text-field v-model="tradeTax.comment" label="Kommentar" box />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex v-if="create" xs12>
              <h2 v-if="Boolean(requiredFiles.length)">Benötigte Dateien</h2>
              <AddTaxFile
                v-for="file in requiredFiles"
                :key="`${tradeTax.calculation_type}_${file.name}`"
                :file="file"
                :id="`${tradeTax.calculation_type}_${file.name}`"
                @addFile="addFile"
              />
            </v-flex>
            <v-flex v-else>
              <TaxTab
                @editRow="editRow"
                :trade-tax-data="tradeTaxClearingData"
              />
            </v-flex>
          </v-layout>
        </v-container>
        <v-container v-if="!tradeTax">
          <v-progress-linear indeterminate />
        </v-container>
      </v-flex>
    </v-layout>
  </app-layout>
</template>

<script>
import AppLayout from '@/components/AppLayout'
import tradeTaxApi from '@/api/tradeTaxApi'
import AddTaxFile from '@/pages/zerlegungView/AddTaxFile'
import TaxTab from '@/pages/zerlegungView/TaxTab'
import { TradeTax } from '@/pages/zerlegungView/TradeTax'
import Yearpicker from '@/components/dates/Yearpicker'
import { translateTradeTaxCalcType } from '@/utils/filters'
import ValidationErrors from '@/components/ValidationErrors'

const patterns = {
  numericString: /^\d+$/,
  string: /^.*$/,
  float: /^[+-]?([0-9]*[,])?[0-9]+$/,
}

const expectedCsvStructures = {
  wageSum: {
    headers: ['AGS', 'Gemeinde', 'Lohnsumme'],
    patterns: [patterns.numericString, patterns.string, patterns.float],
    exampleValues: ['12052000', 'Cottbus', '423000,00'],
  },
  enviamWageSum: {
    headers: ['Gesellschaft', 'AGS', 'Gemeinde', 'Lohnsumme'],
    patterns: [
      patterns.string,
      patterns.numericString,
      patterns.string,
      patterns.float,
    ],
    exampleValues: ['enviaM', '12052000', 'Cottbus', '423000,00'],
  },
  enviamConsumption: {
    headers: ['AGS', 'Gemeinde', 'kWh'],
    patterns: [patterns.numericString, patterns.string, patterns.numericString],
    exampleValues: ['12052000', 'Cottbus', '452967'],
  },
  mitgasConsumption: {
    headers: ['AGS', 'Ortsteil', 'Gemeinde', 'kWh', 'Zaehler'],
    patterns: [
      patterns.numericString,
      patterns.string,
      patterns.string,
      patterns.float,
      patterns.numericString,
    ],
    exampleValues: ['15084200', 'Großkorbetha', 'Weißenfels', '452967', '1'],
  },
}

export default {
  name: 'ZerlegungView',
  components: { ValidationErrors, Yearpicker, TaxTab, AddTaxFile, AppLayout },
  props: {
    create: Boolean,
    id: [Number, String],
  },
  data() {
    return {
      loading: false,
      loadingZip: false,
      weightingFields: {
        enviam: {
          kwh_weight: '% Gewichtung kWh',
          lower_wage_sum_weight: '% Gewichtung Lohnsumme',
        },
        mitgas: {
          kwh_weight: '% Gewichtung kWh',
          wage_weight: '% Gewichtung Lohnsumme',
          gas_meters_weight: '% Gewichtung Gaszähler',
        },
      },
      types: [
        {
          name: translateTradeTaxCalcType('general'),
          value: 'general',
          files: [
            {
              text: 'Datei Lohnsumme',
              name: 'wage_sum_file',
              csvStructure: expectedCsvStructures.wageSum,
            },
          ],
        },
        {
          name: translateTradeTaxCalcType('mitgas'),
          value: 'mitgas',
          files: [
            {
              text: 'Datei Lohnsumme',
              name: 'wage_sum_file',
              csvStructure: expectedCsvStructures.wageSum,
            },
            {
              text: 'Datei Verbrauchsdaten RLM',
              name: 'consumption_rlm_file',
              csvStructure: expectedCsvStructures.mitgasConsumption,
            },
            {
              text: 'Datei Verbrauchsdaten SLP',
              name: 'consumption_slp_file',
              csvStructure: expectedCsvStructures.mitgasConsumption,
            },
          ],
        },
        {
          name: translateTradeTaxCalcType('enviam'),
          value: 'enviam',
          files: [
            {
              text: 'Lohnsummen Unterzerlegung',
              name: 'lower_wage_sum_file',
              csvStructure: expectedCsvStructures.enviamWageSum,
            },
            {
              text: 'Lohnsummen Oberzerlegung',
              name: 'upper_wage_sum_file',
              csvStructure: expectedCsvStructures.enviamWageSum,
            },
            {
              text: 'Verbrauchsdaten',
              name: 'consumption_file',
              csvStructure: expectedCsvStructures.enviamConsumption,
            },
          ],
        },
      ],

      tradeTax: null,
      tradeTaxClearingData: [],
      taxFiles: {},
      validationErrors: null,
    }
  },
  computed: {
    companies() {
      return this.$store.getters.companies
    },
    requiredFiles() {
      const typeFiles = this.types.find(
        (type) => type.value === this.tradeTax.calculation_type
      )
      return typeFiles ? typeFiles.files : []
    },
    weightCorrect() {
      const weighting = this.weightingFields[this.tradeTax.calculation_type]
      if (weighting) {
        return (
          Object.keys(weighting).reduce(
            (sum, weightKey) => sum + this.tradeTax[weightKey],
            0
          ) === 100
        )
      }
      return true
    },
    isValid() {
      const propsDefined = ['company_id', 'calculation_type', 'year'].every(
        (prop) => Boolean(this.tradeTax[prop])
      )

      return this.weightCorrect && propsDefined && this.filesReady
    },
    filesReady() {
      return this.create
        ? !this.requiredFiles.find((file) => !this.taxFiles[file.name])
        : true
    },
  },
  methods: {
    async archivedChange() {
      try {
        await tradeTaxApi.archiveTax(this.tradeTax.id, !this.tradeTax.archived)
        await this.getData()
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
      }
    },
    editRow(id, newValue) {
      const rowIndex = this.tradeTaxClearingData.findIndex(
        (row) => row.id === id
      )
      this.$set(this.tradeTaxClearingData, rowIndex, newValue)
    },
    addFile(name, file) {
      const taxFiles = { ...this.taxFiles }
      taxFiles[name] = file
      this.taxFiles = taxFiles
    },
    async getData() {
      this.loading = true

      if (this.create) {
        this.$set(this, 'tradeTax', new TradeTax())
        this.tradeTax.company_id = this.$store.getters.currentCompany.id
        this.loading = false
      } else {
        try {
          const data = await tradeTaxApi.getTax(this.id)

          this.tradeTax = {
            ...this.tradeTax,
            ...data,
          }
          const { trade_tax_clearing_data } = data
          this.tradeTaxClearingData = trade_tax_clearing_data
        } catch (e) {
          this.$root.setSnackbar('error', e.message)
        } finally {
          this.loading = false
        }
      }
    },
    async update() {
      try {
        this.loading = true
        const tradeTax = {
          ...this.tradeTax,
          trade_tax_clearing_data: this.tradeTaxClearingData,
        }
        await tradeTaxApi.update(this.id, tradeTax)
        await this.getData()
      } catch (e) {
        this.validationErrors = e.errorObject
      } finally {
        this.loading = false
      }
    },
    async upload() {
      try {
        this.loading = true
        let formData = new FormData()
        const taxData = Object.assign(this.tradeTax, this.taxFiles)

        for (let key in taxData) {
          formData.append(key, taxData[key])
        }

        let data = await tradeTaxApi.upload(formData)
        this.$router.push({
          name: 'ZerlegungView',
          params: { id: data.id },
        })
      } catch (e) {
        this.validationErrors = e.errorObject
      } finally {
        this.loading = false
      }
    },
    save() {
      this.validationErrors = null
      this.create ? this.upload() : this.update()
    },
    async exportTax() {
      this.loadingZip = true
      await tradeTaxApi.exportTax(this.tradeTax.id, `${Date.now()}.zip`)
      this.loadingZip = false
    },
  },
  mounted() {
    this.getData()
  },
  watch: {
    'tradeTax.calculation_type'(type) {
      this.taxFiles = {}
      if (type === 'enviam') {
        this.$set(this.tradeTax, 'kwh_weight', 60)
        this.$set(this.tradeTax, 'lower_wage_sum_weight', 40)
      } else if (type === 'mitgas') {
        this.$set(this.tradeTax, 'kwh_weight', 30)
        this.$set(this.tradeTax, 'gas_meters_weight', 30)
        this.$set(this.tradeTax, 'wage_weight', 40)
      }
    },
    id() {
      this.getData()
    },
  },
}
</script>

<style scoped></style>
