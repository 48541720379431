import formatMoney from 'accounting-js/lib/formatMoney'

export function allPayments(payment) {
  if (payment) {
    return payment.payment_lines
  }
  return []
}

export function formatIban(iban) {
  const EVERY_FOUR_CHARS = /(.{4})(?!$)/g
  const NON_ALPHANUM = /[^a-zA-Z0-9]/g
  return iban
    .replace(NON_ALPHANUM, '')
    .toUpperCase()
    .replace(EVERY_FOUR_CHARS, '$1 ')
}

export function taxSum(payment) {
  return allPayments(payment)
    .map((el) => Number(el.tax?.value || 0))
    .reduce((memo, curr) => memo + curr, 0)
}

export function interestSum(payment) {
  return allPayments(payment)
    .map((el) => Number(el.interest?.value || 0))
    .reduce((memo, curr) => memo + curr, 0)
}
export function interestAdditionalSum(payment) {
  return allPayments(payment)
    .map((el) => Number(el.interest_additional?.value || 0))
    .reduce((memo, curr) => memo + curr, 0)
}
export function interestRefundSum(payment) {
  return allPayments(payment)
    .map((el) => Number(el.interest_refund?.value || 0))
    .reduce((memo, curr) => memo + curr, 0)
}

export function interestAllSum(payment) {
  return allPayments(payment)
    .map(
      (el) =>
        Number(el.interest?.value || 0) +
        Number(el.interest_refund?.value || 0) +
        Number(el.interest_additional?.value || 0)
    )
    .reduce((memo, curr) => memo + curr, 0)
}

export function paymentSum(payment) {
  return taxSum(payment) + interestSum(payment)
}

export function taxSaldo(payment) {
  return payment.businessTax - taxSum(payment)
}

export function interestSaldo(payment) {
  return interestSum(payment)
}

export function interestCalculationTaxBase(originalTax) {
  return originalTax - (originalTax % 5000) // cent based thats why we use 5000 instead of 50
}

export function interestRounded(interest, type) {
  // interest is cent-based.
  if (type === 'interest_additional') {
    return interest % 100 // for additional taxes we round down
  } else {
    return (interest % 100) + 100 // for refunds we round up
  }
}

export function formatAmountToCurrency(value) {
  if (typeof value === 'number') {
    return formatMoney(value / 100, {
      symbol: '€',
      precision: 2,
      thousand: '.',
      decimal: ',',
      format: '%v %s',
    })
  }
  return '--'
}

export function editableAmount(value) {
  return formatMoney(value / 100, {
    symbol: '€',
    precision: 2,
    thousand: '',
    decimal: ',',
    format: '%v',
  })
}

export function paymentTypeToName(type) {
  return (
    {
      prePayments: 'Vorauszahlung',
      secondaryPrePayments: 'Korrekturzahlung',
      taxAuditPayments: 'Betriebsprüfung',
      finalPayments: 'Abschlusszahlung',
      additionalPayments: 'Nachzahlung',
    }[type] || ''
  )
}

export function isValidIBANNumber(input) {
  function mod97(string) {
    let checksum = string.slice(0, 2)
    let fragment
    for (let offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7)
      checksum = parseInt(fragment, 10) % 97
    }
    return checksum
  }

  const CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
  }
  // keep only alphanumeric characters
  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '')
  // match and capture (1) the country code, (2) the check digits, and (3) the rest
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/)
  let digits
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55
  })
  // final check

  return mod97(digits) === 1
}

export function isValidBic(value) {
  return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(
    value.toUpperCase()
  )
}
