<template>
  <v-text-field :label="label" @blur="checkAmount" box v-model="inputValue" />
</template>
<script>
export default {
  name: 'SimpleMoneyInput',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      inputValue: '0',
    }
  },
  methods: {
    setFormattedAmount(value) {
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        useGrouping: false,
      })
      this.inputValue = formatter.format(value / 100)
    },
    checkAmount() {
      const number = Number(`${this.inputValue}`.replace(',', '.'))
      if (Number.isNaN(number)) {
        this.setFormattedAmount(this.value)
      } else {
        const result = number.toFixed(2) * 100
        this.updateModel(result)
      }
    },
    updateModel(value) {
      this.$emit('input', value)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: 'setFormattedAmount',
    },
  },
  mounted() {
    this.$el.querySelector('input')?.focus()
  },
}
</script>
