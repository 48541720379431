<template>
  <v-card>
    <v-tabs v-model="tabIndex">
      <v-tab :key="tab.key" v-for="tab in tabs">
        {{ tab.key | translateTradeTaxCalcType }}
      </v-tab>
      <v-tab-item :key="tab.key" v-for="tab in tabs">
        <TaxTable
          :base-type="tab.baseType"
          :trade-tax-data="tableDataByType[tab.baseType][tab.type]"
          :type="tab.type"
          @editRow="
            (id, value) => {
              $emit('editRow', id, value)
            }
          "
        />
      </v-tab-item>
    </v-tabs>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import TaxTable from '@/pages/zerlegungView/TaxTable'
import { translateTradeTaxCalcType, formatKwhUsage } from '@/utils/filters'

export default {
  name: 'TaxTab',
  components: { TaxTable },
  props: {
    tradeTaxData: Array,
  },
  data() {
    return {
      tabIndex: null,
      tablesData: [],
    }
  },
  computed: {
    tableDataByType() {
      const result = {
        wage_sum_type: {},
        consumption_type: {},
      }
      this.tradeTaxData.forEach((elem) => {
        const baseType = elem.wage_sum_type
          ? 'wage_sum_type'
          : 'consumption_type'
        const type = elem[baseType]
        if (!result[baseType][type]) {
          result[baseType][type] = []
        }
        result[baseType][type].push(elem)
      })
      return result
    },
    tabs() {
      const result = []
      ;['wage_sum_type', 'consumption_type'].forEach((baseType) => {
        Object.entries(this.tableDataByType[baseType]).forEach(([type]) => {
          result.push({
            key: `${baseType}-${type}`,
            baseType,
            type,
          })
        })
      })
      return result
    },
  },
  filters: {
    translateTradeTaxCalcType,
    formatKwhUsage,
  },
}
</script>

<style scoped></style>
